import React from "react"
import { Row, Col, Container, Card } from "react-bootstrap"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Blurb from "../components/blurb"
import Header from "../components/header"
import nissanLogo from "../images/nissan.svg.png"
import kiaLogo from "../images/kia-logo.svg.png"
import mitsubishiLogo from "../images/mitsubishi.svg.png"
import hyundaiLogo from "../images/hyundai-logo.svg"
import hondaLogo from "../images/honda.svg"
import bcLogo from "../images/bc.png"
import abLogo from "../images/ab.png"
import skLogo from "../images/sk.png"
import ytLogo from "../images/yukon.jpeg"

export const query = graphql`
  {
    nissanLogo: file(relativePath: { eq: "nissan.png" }) {
      childImageSharp {
        fluid(maxWidth: 40) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    memberLogo: file(relativePath: { eq: "memberships.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const email = process.env.GATSBY_EMAIL_ADDRESS
  const logo = data.memberLogo.childImageSharp.fluid

  const text = (
    <>
      Give us a call @{" "}
      <a className="text-light" href={`tel: ${phoneNum}`}>
        {phoneNum}
      </a>{" "}
      or email <a href={`mailto: ${email}`}>{email}</a>. You can also get more
      information from any one of the dealerships listed below.
    </>
  )

  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "dealerships" }}
    >
      <Seo
        title="Dealerships"
        keywords={[`dealerships`, `participating`, `secure ride, protection`]}
      />
      <Header logo={logo.src} />
      <h2
        style={{
          color: "white",
          textAlign: "center",
          paddingTop: "15px",
        }}
      >
        Contact Us
      </h2>
      <Blurb text={text} />
      <Container fluid>
        <Row className="my-2 py-2" display="flex">
          <Col
            className="d-flex justify-content-end"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-dealers card-bc" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      src={bcLogo}
                      variant="top"
                      alt="BC Logo"
                      className="page-logo-img pb-2"
                    />
                    <Card.Title>British Columbia Dealers:</Card.Title>
                    <Card.Text>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr",
                          justifyItems: "start",
                        }}
                      >
                        <a href="https://www.cranbrookkia.com/">
                          <span>
                            <img
                              src={kiaLogo}
                              height="9rem"
                              style={{ marginRight: "5px" }}
                              alt="Kia Logo"
                            />
                            Cranbrook KIA
                          </span>
                        </a>
                        <a
                          href="https://www.nanaimomitsubishi.ca/"
                          // style={{ marginLeft: "9px" }}
                        >
                          <span>
                            <img
                              src={mitsubishiLogo}
                              style={{ marginRight: "15px" }}
                              height="35rem"
                              alt="Mitsubishi Logo"
                            />
                            Nanaimo Mitsubishi
                          </span>
                        </a>
                        <a
                          href="https://www.nissanofnanaimo.com/"
                          style={{ marginTop: "3px" }}
                        >
                          <span>
                            <img
                              src={nissanLogo}
                              alt="Nissan Logo"
                              style={{ marginRight: "11px" }}
                              height="25rem"
                            />
                            Nissan of Nanaimo
                          </span>
                        </a>
                        <a
                          href="https://www.nissanofduncan.com/"
                          style={{ marginTop: "3px" }}
                        >
                          <span>
                            <img
                              src={nissanLogo}
                              alt="Nissan Logo"
                              style={{ marginRight: "11px" }}
                              height="25rem"
                            />
                            Nissan of Duncan
                          </span>
                        </a>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex justify-content-start"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-dealers card-bc" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      src={abLogo}
                      variant="top"
                      alt="Alberta Logo"
                      className="page-logo-img pb-2"
                    />
                    <Card.Title>Alberta Dealers:</Card.Title>
                    <Card.Text>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr",
                          justifyItems: "start",
                        }}
                      >
                        <a href="https://www.frontiermitsubishi.ca/">
                          <span style={{ marginLeft: "9px" }}>
                            <img
                              src={mitsubishiLogo}
                              alt="Mitsubishi Logo"
                              style={{ marginRight: "12px" }}
                              height="35rem"
                            />
                            Frontier Mitsubishi
                          </span>
                        </a>
                        <a href="https://www.kiareddeer.ca/">
                          <span>
                            <img
                              src={kiaLogo}
                              alt="Kia Logo"
                              height="9rem"
                              style={{ marginRight: "12px" }}
                            />
                            KIA Red Deer
                          </span>
                        </a>
                        <a href="https://www.leduchyundai.com/">
                          <span style={{ marginTop: "3px" }}>
                            <img
                              src={hyundaiLogo}
                              alt="Hyundai Logo"
                              style={{ marginRight: "5px" }}
                              // height="32rem"
                              width="45rem"
                            />
                            Leduc Hyundai
                          </span>
                        </a>
                        <a href="https://www.kialethbridge.ca/">
                          <span style={{ marginTop: "3px" }}>
                            <img
                              src={kiaLogo}
                              alt="Kia Logo"
                              height="9rem"
                              style={{ marginRight: "12px" }}
                            />
                            Lethbridge KIA
                          </span>
                        </a>
                        <a href="https://www.lethbridgemitsubishi.ca/">
                          <span style={{ marginLeft: "9px" }}>
                            <img
                              src={mitsubishiLogo}
                              alt="Mitsubishi Logo"
                              style={{ marginRight: "12px" }}
                              height="35rem"
                            />
                            Lethbridge Mitsubishi
                          </span>
                        </a>
                        <a
                          href="https://www.lloydminsterhonda.ca/"
                          style={{ marginTop: "5px" }}
                        >
                          <span>
                            <img
                              src={hondaLogo}
                              alt="Honda Logo"
                              height="25rem"
                              style={{ marginRight: "10px" }}
                            />
                            Lloydminster Honda
                          </span>
                        </a>
                        <a href="https://www.northlandkia.ca/">
                          <span style={{ marginTop: "3px" }}>
                            <img
                              src={kiaLogo}
                              alt="Kia Logo"
                              height="9rem"
                              style={{ marginRight: "12px" }}
                            />
                            Northland KIA
                          </span>
                        </a>
                        <a
                          href="https://www.reddeermitsubishi.ca/"
                          style={{ marginLeft: "9px" }}
                        >
                          <span>
                            <img
                              src={mitsubishiLogo}
                              alt="Mitsubishi Logo"
                              style={{ marginRight: "12px" }}
                              height="35rem"
                            />
                            Red Deer Mitsubishi
                          </span>
                        </a>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-2 py-2 pb-10">
          <Col
            className="d-flex align-items-center justify-content-end"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={12}>
                <Card className="card-dealers card-bc" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      src={skLogo}
                      variant="top"
                      alt="Saskatchewan Logo"
                      className="page-logo-img pb-2"
                    />
                    <Card.Title>Saskatchewan Dealers:</Card.Title>
                    <Card.Text>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr",
                          justifyItems: "start",
                        }}
                      >
                        <a href="https://www.lloydminsterhyundai.com/">
                          <span style={{ marginTop: "3px" }}>
                            <img
                              src={hyundaiLogo}
                              alt="Hyundai Logo"
                              style={{ marginRight: "11px" }}
                              // height="32rem"
                              width="45rem"
                            />
                            Lloydminster Hyundai
                          </span>
                        </a>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex justify-content-start"
            sm={{ span: 6, order: 2 }}
          >
            <Row>
              <Col sm={10}>
                <Card className="card-dealers card-bc" border="dark" bg="light">
                  <Card.Body>
                    <Card.Img
                      src={ytLogo}
                      variant="top"
                      alt="Yukon Logo"
                      className="page-logo-img pb-2"
                    />
                    <Card.Title>Yukon Dealers:</Card.Title>
                    <Card.Text>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "2fr",
                          justifyItems: "start",
                        }}
                      >
                        <a
                          href="https://www.yukonnissan.com/"
                          style={{ marginTop: "3px" }}
                        >
                          <span>
                            <img
                              src={nissanLogo}
                              alt="Nissan Logo"
                              style={{ marginRight: "11px" }}
                              height="25rem"
                            />
                            Yukon Nissan
                          </span>
                        </a>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
